.App {
  text-align: center;
  height: 100%;
}

.active {
  color: teal;
  font-family: 'Work Sans', sans-serif;
  font-size: .9em;
  padding: 15px;
  line-height: .5em;
}

.centered {
  text-align: center;
  margin: auto;
  padding: auto;
  align-content: center;
  align-items: center;
  position: relative;
}

.imgMic {
  display: block;
  min-height: 180px;
  max-width: 258px;
  width: auto;
  height: auto;
  margin: 40px auto auto;
  box-shadow: 0px 2px 5px #999;
}

.imgInstructions {
  display: block;
  margin: 40px auto;
}

.linkNav {
  color: #222;
  font-family: 'Work Sans', sans-serif;
  font-size: .9em;
  text-decoration: none;
  padding: 15px;
  line-height: .5em;
}

.linkNav:hover {
  color: teal;
  text-shadow: 2px 2px 2px #DDD;
}

.logoImg {
  width: 80px;
  margin: 20px 0px auto;
}

.logoTextLg {
  font-family: 'Dosis', sans-serif;
  font-size: 1.4em;
}

.logoTextSm {
  font-family: 'Dosis', sans-serif;
  font-size: 0.8em;
}

.navBar {
  line-height: .25em;
  position: fixed;
}

.page {
  background-color: #FFF;
  width: 75%;
  height: 100%;
  box-shadow: 4px 4px 4px #DDD, -4px -4px 4px #DDD;
}

.text {
  font-family: 'Libre Baskerville', serif;
  font-size: 1em;
  line-height: 1.5em;
  text-align: justify;
  white-space: pre-line;
}

.textCaption {
  color: rgb(128, 128, 128);
  font-family: 'Work Sans', sans-serif;
  font-size: .9em;
  line-height: .5em;
  padding-top: 12px;
}

.textTitle {
  font-family: 'Work Sans', sans-serif;
  font-size: 2.25em;
  line-height: 2.25em;
  text-align: center;
}

.textSubtitle {
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 1.25em;
  line-height: 2em;
  color: #222;
  text-align: center;
}

body {
  background-color: #EEE;
  font-size: 16px;
}

input, select, textarea {
  background-color: #F9F9F9 !important;
}

li {
  list-style: none;
}

ul {
  padding: auto;
}
